<template>

  <div class="container">

      <section class="section">
        <div class="columns">
          <div class="column">

            <div class="card">

              <div v-if="!isUser" class="notification is-primary heading">
                <h2 class="is-size-5 has-text-centered">Create {{planType != undefined ? 'Your' : 'a Free'}} Profile</h2>
                
              </div>

              <div v-else class="dashtile-wrapper">

                 <div class="spacer s20"></div>

                  <p class="dash-heading is-size-4 has-text-centered">You are already a member!</p>

                  <div class="spacer s30"></div>

                  <div @click="toPage('my-dashboard')" class="dashtile vessels">
                    <div class="content-wrap">
                      <font-awesome-icon icon="home" />
                      <p class="title">My Dashboard</p>
                    </div>
                  </div>
                

                
                  <div @click="toPage('Home')" class="dashtile crew">
                    <div class="content-wrap">
                      <font-awesome-icon icon="star" />
                      <p class="title">Home Page</p>
                    </div>
                  </div>

                  <div class="clear"><p>&nbsp;</p></div>

              </div>

              

              <div v-if="!isUser" class="card-content">


                  <div class="step-wrapper">
                 
                    <ul class="steps is-horizontal">
                      <li class="steps-segment" :class="{'is-active':step === 1}">
                        <span class="steps-marker">1</span>
                      </li>
                      <li class="steps-segment" :class="{'is-active':step === 2}">
                        <span class="steps-marker">2</span>
                      </li>
                      <li class="steps-segment" :class="{'is-active':step === 3}">
                        <span class="steps-marker">3</span>
                      </li>
                      
                    </ul>

                 </div>

                  <form class="signup-form" v-on:submit.prevent="newSignUp" autocomplete="off" >


                    <transition name="slide-fade">

                        <div class="slide-section" v-if="step === 1">

                            <div class="form-group">      
                              <input class="input" type="text" v-model="fullname" required>
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label>First and last name</label>
                            </div>

                            <div class="form-group">      
                              <input class="input" type="email" v-model="email" required>
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label>Email address</label>
                            </div>

                            <div class="buttons" v-if="stepOneComplete">
                              <button class="button is-info next" @click.prevent="next()">Next</button>
                            </div>

                            <div class="clear"></div>

                        </div>

                    </transition>


                    <transition name="slide-fade">

                        <div class="slide-section" v-if="step === 2">

                            <div class="form-group">      
                              <input autocomplete="off" autocorrect="off" minlength="6" maxlength="64" autocapitalize="off" spellcheck="false" class="input" type="text" v-model="password" required>
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label>Password (min 6 characters)</label>
                            </div>

                            <div class="form-group">      
                              <input class="input" type="tel" v-model="phone" :maxlength="10" v-on:keypress="isNumber($event)" required>
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label>Australian Mobile number (04..)</label>
                            </div>

                            <div class="buttons">
                              <button class="button is-info previous" @click.prevent="prev()">Previous</button>
                              <button v-if="stepTwoComplete && phoneNumberValid" class="button is-info next" @click.prevent="next()">Next</button>
                            </div>

                            <div class="clear"></div>

                        </div>

                    </transition>


                    <transition name="slide-fade">

                        <div class="slide-section" v-if="step === 3">

                            <!-- <div class="form-group centered-mobile">   
                                <div class="select is-primary">
                                  <select v-model="state">
                                    <option disabled value="">Your State</option>
                                    <option v-for="state in allstates" :key="state.id">
                                      {{ state.name }}
                                    </option>
                                  </select>
                                </div>
                            </div> -->

                            <div class="form-group centered-mobile">   
                                <div class="select is-primary">
                                  <select v-model="userKind">
                                    <option disabled value="">How would you describe yourself?</option>
                                    <option v-for="kind in userKinds" :key="kind.id">
                                      {{ kind.name }}
                                    </option>
                                  </select>
                                </div>
                            </div>

                            <div class="buttons">
                              <button class="button is-info previous" @click.prevent="prev()">Previous</button>
                              <button v-if="stepThreeComplete" class="button is-info next" @click.prevent="next()">Next</button>
                            </div>

                            <div class="clear"></div>

                        </div>

                    </transition>


                    <transition name="slide-fade">

                        <div class="slide-section" v-if="step === 4">

                            <p class="has-text-centered">You're ready to join the SafeTruckie family!</p>

                            <p>&nbsp;</p>

                            <button :class="{active: showSignUp}" type="submit" class="signMeUp button is-success">{{signUpClicked ? 'Creating Profile...' : 'Create my profile!'}}</button>

                            <div v-if="buttonRole" class="notification is-primary"> {{buttonRole.msg}} </div>                        

                            <div class="small-text has-text-centered">
                              By joining SafeTruckie you agree to our 
                              <span @click="readTerms" class="simple-link">Terms of Use</span>
                            </div>

                            <hr>



                            <div v-if="!signUpClicked" class="buttons">
                              <button class="button is-info previous" @click.prevent="prev()">Previous</button>
                            </div>

                            <div class="clear"></div>
                       
                        </div>

                    </transition>
                      


                    <div class="clear"></div>


                  </form>




              </div>

            </div>

        

            <div v-if="!signUpClicked" class="columns is-mobile is-centered">
              <div class="column">
                <p class="bd-notification is-primary has-text-centered">
                  <router-link v-if="!isUser" to="sign-in">Member Log-in</router-link>
                  <span class="simple-link" v-else @click="signOut">Sign Out</span>
                  <!-- <router-link to="feedback">Get Help</router-link> -->
                </p>
              </div>
            </div>

            <simple-modal title="Oops! That didn't work!" v-on:closed="showSimpleModal = false"  v-if="showSignupError">
              <p>Please try again and if you still can't sign up, please call us on <a href="tel:0409-709-596">0409 709 596</a> and we can help you over the phone.</p>

            </simple-modal>

            <simple-modal title="SafeTruckie Terms of Use" v-on:closed="showSimpleModal = false"  v-if="showSimpleModal">
              <p>Please take the time to read these terms and conditions. By using Our Website and the Services and information offered on Our Website, you are agreeing to these terms and conditions.</p>
              <p>If you purchase products through SafeTruckie, there will be additional terms and conditions relating to the purchase.  Please make sure you agree with these terms and conditions, which you will be directed to read prior to making your purchase.</p>
              <h4>Definitions</h4>
              <p><strong>Services</strong> means the cloud based Safety Management System tools provided by SafeTruckie. </p>
              <p><strong>the Website</strong> means the web app located at www.safetruckie.com.au.</p>
              <p><strong>We / Us</strong> etc means SafeTruckie Pty Ltd and any holding companies, affiliates, employees, officers, agents or assigns.</p>
              <h4>Accuracy of content</h4>
              <p>We have taken proper care and precautions to ensure that the information we provide on this Website is accurate.  However, we cannot guarantee, nor do we accept any legal liability arising from or connected to, the accuracy, reliability, currency or completeness of anything contained on this Website or on any linked site.</p>
              <p>The information contained on this Website should not take the place of professional advice or proper due diligence and care. </p>
              <h4>Use</h4>
              <p>The Website is made available for your use on your acceptance and compliance with these terms and conditions.  By using this Website, you are agreeing to these terms and conditions.</p>
              <p>You agree that you will use this website in accordance with all applicable local, state, national and international laws, rules and regulations.</p>
              <p>You agree that you will not use, nor will you allow or authorise any third party to use, the Website for any purpose that is unlawful, defamatory, harassing, abusive, fraudulent or obscene way or in any other inappropriate way or in a way which conflicts with the Website or the Services.</p>
              <p>If you contribute to our forum (if any) or make any public comments on this Website which are, in our opinion, unlawful, defamatory, harassing, abusive, fraudulent or obscene or in any other way inappropriate or which conflict with the Website or the Services offered, then we may at our discretion, refuse to publish such comments and/or remove them from the Website.</p>
              <p>We reserve the right to refuse or terminate service to anyone at any time without notice or reason.</p>
              <h4>Passwords and logins</h4>
              <p>You are responsible for maintaining the confidentiality of your passwords and login details and for all activities carried out under your password and login.</p>
              <h4>Indemnification for loss or damage</h4>
              <p>You agree to indemnify Us and hold Us harmless from and against any and all liabilities or expenses arising from or in any way related to your use of this Website or the Services or information offered on this Website, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and solicitors fees of every kind and nature incurred by you or any third parties through you.</p>
              <h4>Intellectual property and copyrights</h4>
              <p>We hold the copyright to the content of this Website, including all uploaded files, layout design, data, graphics, articles, file content, codes, news, tutorials, videos, reviews, forum posts and databases contained on the Website or in connection with the Services. You must not use or replicate our copyright material other than as permitted by law.  Specifically, you must not use or replicate our copyright material for commercial purposes unless expressly agreed to by Us, in which case we may require you to sign a Licence Agreement.  </p>
              <p>If you wish to use content, images or other of our intellectual property, you should submit your request to us at the following email address: <strong>help@safetruckie.com.au</strong></p>
              <h4>Trademarks</h4>
              <p>Any trademarks and logos contained on this Website will be the trademarks of SafeTruckie Pty Ltd or its parent company. Use of these trademarks is strictly prohibited except with Our express, written consent.</p>
              <h4>Links to external websites</h4>
              <p>This Website may contain links that direct you outside of this Website.  These links are provided for your convenience and are not an express or implied indication that we endorse or approve of the linked Website, it’s contents or any associated website, product or service.  We accept no liability for loss or damage arising out of or in connection with your use of these sites.  </p>
              <p>You may link to our articles or home page.  However, you should not provide a link which suggests any association, approval or endorsement on our part in respect to your website, unless we have expressly agreed in writing.  We may withdraw our consent to you linking to our site at any time by notice to you.</p>
              <h4>Limitation of Liability</h4>
              <p>We take no responsibility for the accuracy of any of the content or statements contained on this Website or in relation to our Services.  Statements made are by way of general comment only and you should satisfy yourself as to their accuracy.  Further, all of our Services are provided without a warranty with the exception of any warranties provided by law.  We are not liable for any damages whatsoever, incurred as a result of or relating to the use of the Website or our Services.</p>
              <h4>Confidentiality</h4>
              <p>All personal information you give us will be dealt with in a confidential manner in accordance with our Privacy Policy.  However, due to circumstances outside of our control, we cannot guarantee that all aspects of your use of this Website will be confidential due to the potential ability of third parties to intercept and access such information.</p>
              <h4>Governing Law</h4>
              <p>These terms and conditions are governed by and construed in accordance with the laws of Australia.  Any disputes concerning this website are to be resolved by the courts having jurisdiction in Australia. </p>
              <p>We retain the right to bring proceedings against you for breach of these Terms and Conditions, in your country of residence or any other appropriate country or jurisdiction.</p>

            </simple-modal>
            
          </div>
        </div>
      </section>

    </div>
    
</template>


<script>
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';
import 'firebase/functions';
import { db } from '../main.js';
import NProgress from 'nprogress';

const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
  mounted: function () {   

    let d = new Date();
    this.today = d;

    let yearOut = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    this.anniversary = yearOut;

    let twoMonthsOut = new Date(new Date().setMonth(new Date().getMonth() + 2));
    this.trialEndDate = twoMonthsOut;
    console.log(this.trialEndDate);

    firebase.auth().onAuthStateChanged( this.onUserLogin );     
  },
  props: {
    planType: String,
    maxTrucks: Number,
    chargeAmount: Number
  },
  components: {
    SimpleModal,
  },
  computed: {
    showSignUp() {
      return this.state != '' && this.industry != '' && this.fullname != '' && this.isEmailValid() && this.password.length > 5 && this.phone != '';
    },
    pwLength() {
      return this.password.length;
    },
    stepOneComplete() {
      return this.fullname != '' && this.isEmailValid();
    },
    stepTwoComplete() {
      return this.password.length > 5 && this.phone != '';
    },
    stepThreeComplete() {
      // return this.state != '' && this.industry != '';
      return this.userKind != '';
    },
    phoneNumberValid() {
      return this.phone.length == 10 && this.phone.slice(0,2) == '04';
    },
    finalNumber() {
      return "+61" + this.phone.slice(1,10);
    },
    firebaseToday() {
      let today = new Date();
      return new firebase.firestore.Timestamp.fromDate(today); 
    },
    firebaseAnniversary() {
      let anniversary = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      console.log('Anniversary: ',anniversary);
      return new firebase.firestore.Timestamp.fromDate(anniversary); 
    }
  },
  data: function() {
    return {
      userid: '',
      email: '',
      password: '',
      fullname: '',
      phone: '',
      state: '',
      industry: '',
      userKind: '',
      myrole: '',
      chosenRole: '',
      allstates: [
        { id: "1", name: "Tasmania" },
        { id: "2", name: "Victoria" },
        { id: "3", name: "New South Wales" },
        { id: "4", name: "Queensland" },
        { id: "5", name: "South Australia" },
        { id: "6", name: "Western Australia" },
        { id: "7", name: "Northern Territory" }
      ],
      roles: [
        {id: "1", kind: "Vessel Owner", desc: "Vessel owner", msg: "As a vessel owner, you will be able to add vessels and set up an SMS for each vessel. You will be redirected to the payment page in a moment..."},
        {id: "2", kind: "Crew", desc: "Deckhand", msg: "Free for all deckhands and crew."},
        {id: "3", kind: "Contractor", desc: "Contractor on a vessel", msg: "Free for all contractors and marine surveyors."}
      ],
      userKinds: [
        { id: "1", name: 'I own a truck'},
        { id: "2", name: 'I own a fleet of trucks'},
        { id: "3", name: 'I drive trucks for other people'},
        { id: "4", name: "Other"},
      ],
      signUpMessage: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      buttonRole: '',
      today: '',
      anniversary: '',
      trialEndDate: '',

      step: 1,
      signUpClicked: false,
      showSimpleModal: false,

      isUser: false,
      firebaseid: '',
      firebasedisplayname: '',
      showSignupError: false,

    };
  },
  methods: {

    onUserLogin: function( user ) {
      if (user) {         
        this.isUser = true;
        this.firebaseid = user.uid;
        this.firebasedisplayname = user.displayName;
      }   
    },

    signOut: function() {

      var self = this;
      var addRevision = firebase.functions().httpsCallable('addRevision');
      addRevision({
        userID: self.firebaseid,
        userName: self.firebasedisplayname,
        description: 'A user has signed out.',
      });

      var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
      userHasEngaged({
        userID: self.firebaseid,
        userName: self.firebasedisplayname,
        message: 'A user has signed out of SafeTruckie.',
        subject: 'A user has signed out'
      });

      firebase.auth()
        .signOut()
        .then(() => {
          this.$store.dispatch('resetStates');
          this.$router.replace('sign-in');
        });

    },

    toPage(page) {
      this.$router.push({ 
        name: page
      });
    },

    isEmailValid: function() {
      return (this.email == "") ? false : (this.reg.test(this.email)) ? true : false;
    },

    selectRole: function(role) {
      this.buttonRole = role;
    },

    isNumber: function($evt) {
      $evt = ($evt) ? $evt : window.event;
      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $evt.preventDefault();
      } else {
        return true;
      }
    },

    prev() {
      this.step--;
    },

    next() {
      this.step++;
    },

    newSignUp: function() {

      NProgress.start();
      let self = this;
      this.signUpClicked = true;
      let fixedEmail = this.email.trim();

      var createNewUserAtSignup = firebase.functions().httpsCallable('createNewUserAtSignup');
      createNewUserAtSignup({
        // send newly collected signup form data 
        username: self.fullname ? self.fullname : 'No name',
        useremail: fixedEmail ? fixedEmail : '',
        password: self.password ? self.password : '',
        userphone: self.finalNumber ? self.finalNumber : '', 
        userrole: 'free',
        freeTrial: true,
        // userstate: self.state,
        // industry: self.industry,
        userKind: self.userKind,
        // hasTakenDashboardTour: false,
        maxTrucks: 0,
        lastPayment: 'Complete',   
        //
        // 'username': fullName,
        // 'useremail': emailAddress,
        // 'password': userPassword,
        // 'userphone': newPhoneNum,
        // 'userrole': 'free',
        // 'freeTrial': true,
        // 'userKind': userKind,
        // 'maxTrucks': 0,
        // 'lastPayment': 'Complete',    

      })
      .then(function(result) {
        //let sanitizedMessage = result.data.text;
        if (result.data.message == 'Success') {
          console.log('User created successfully with ID ',result.data.newuserid);
          // do something if user created successfully:
          self.signInByEmail(result.data.newuserid);          

        } else {
           console.log('Error creating user: ',result.data.text);
           NProgress.done();
           self.showSignupError = true;
          // do something on user creation error:

        }
        //console.log(result.data);
        NProgress.done();
      })
      .catch(function(error) {
        console.log('Error: ',error);
        console.log('Text Error: ',error.text);
        //console.log(error.code,error.message,error.details);
        NProgress.done();
      });  


    },

    readTerms: function() {
      this.showSimpleModal = true;
      let addRevision = firebase.functions().httpsCallable('addRevision');
      addRevision({
        userID: 'No User ID',
        userName: 'A visitor',
        description: 'Someone has read our terms and conditions',
      });
    },

    signInByEmail: function(userid) {
      NProgress.start();
      var self = this;
      firebase.auth().signInWithEmailAndPassword(self.email, self.password)
        .then( user => {
          console.log(user);
          let futureDate = new Date(self.anniversary);
          let futureTrialEndDate = new Date(self.trialEndDate);
          console.log(futureDate);
          db.collection('userdata').doc(userid).update({
            //signUpDate: self.firebaseToday,
            //anniversaryDate: self.firebaseAnniversary,
            anniversaryDate: futureDate,
            trialEndDate: futureTrialEndDate,
          })
          // .then(function() {
          //   db.collection('revisions').add({
          //     userID: userid,
          //     userName: self.fullname,
          //     revisionDate: firebase.firestore.FieldValue.serverTimestamp(),
          //     description: 'New user signed up.',
          //   });
          // })
          .then(function() {
              self.$store.dispatch('updateUserState');
              //self.$router.replace('welcome');

              var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
              userHasEngaged({
                userID: userid, 
                userName: self.fullname,
                message: 'A new user has created an account on SafeTruckie',
                subject: 'NEW USER SIGN UP!'
              }); 

              // self.$router.replace('welcome');
              self.$router.replace('my-dashboard');

          });
        },
        error => {
          NProgress.done();
          alert(error.message);
        }
      );
    },



  }
};
</script>

<style lang="scss" scoped>

  .notification {
    margin-top: 5px !important;
    padding: 0.9rem 2rem 0.7rem 2rem;
    &.heading {
      margin-bottom: 0;
    }
  }
  .signup-billing {
    text-align: left;
    border: 1px solid red;
    padding: 1rem;
    border-radius: 4px;
  }
  .select.left {
    float: left;
  }
  .role-message {
    float: left;
    padding-top: 6px;
    margin-left: 1rem;
  }


  .bd-notification {
    margin-top: 1rem;
  }

  // [v-cloak] {
  //   display: none;
  // }

  .help-block {
    color: red;
    font-size: 13px;
  }

  label.uk-form-label {
    color: blue;
  }

  button.uk-button-primary {
    color: yellow;
    font-weight: bold;
    background-color: #1e87f0
  }

  #card-number,
  #card-cvc,
  #card-expiry {
    padding-top: 10px;
  }

  .stripeError {
    color: red;
    font-style: italic;
  }

  .button {
    &.role {
      border-color: #00d1b2;
      color: #00d1b2;
      &.active {
        background-color: #00d1b2;
        border-color: #00d1b2;
        color: #fff;
      }
    }
  }

  h2 {
    line-height: 1.5rem;
  }



  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    //transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100%);
    opacity: 0;
  }

  .buttons {
    width: 100%;
    position: relative;
    margin-bottom: 5rem;
    .button {
      position: absolute;
      top: 0;
      &.next {
        right: 0;
      }
      &.previous {
        left: 0;
      }
    }
    &.center {
      text-align: center;
    }
  }

  .step-wrapper {
    margin: 1rem 0;
    padding-bottom: 0.5rem;
    .steps-segment {
      cursor: pointer;
    }
  }

  .signup-form {
    
    // position: relative;
    // height: 600px;
    .slide-section {
      // position: absolute;
      top: 0;
      vertical-align: top;
      padding-bottom: 2rem;
      // border-bottom: 1px solid #eee;
    }
  }

  .goodtogo {
    margin-top: 2rem;
  }

  .goforit {
    position: fixed;
    bottom: -60px;
    width: 100%;
    background-color: #209cee;
    padding: 13px;
    color: #fff;
    left: 0;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      bottom: 0;
    }
  }

  button.signMeUp {
    margin: 1rem auto 2rem;
    display: block;
    padding: 12px 26px;
    height: auto;
    font-size: 1.5rem;
  }
  .small-text {
    font-size: 0.8rem;
    label {
      font-size: 0.8rem;
    }
  }
  h4 {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 4px;
  }

  .dashtile-wrapper {
    .dashtile {
      width: 46%;
      float: left;
      background: red;
      margin: 0 2% 18px;
      text-align: center;
      height: 200px;
      color: #fff;
      border-radius: 5px;
      transform-style: preserve-3d;
      transition: 0.3s;
      cursor: pointer;
      transition: 0.3s;     
      &.active {
        transform: scale(1.05);
      }
      // &:hover {
      //  transform: scale(1.05);
      // }
      p {
        color: #ffffff !important;
        margin-bottom: 0;
        text-align: center;
        &.count {
          font-size: 22px;
        }
      }
      &.alerts {
        background: #ffcd2e;
      }
      &.profile {
        background: #d4d4d4;
        p, .content-wrap {
          color: #444;
        }
      }
      &.vessels {
        background: #00d1b2;
      }
      &.crew {
        background: #209cee;
      }
      &.jobs {
        background: #ffdd57;
      }
      .content-wrap {       
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        svg {
          font-size: 20px;
          margin-bottom: 4px;
        }
      }
    }
    h3 {
      max-width: 400px;
      margin: 0 auto;
    }
  }



</style>
