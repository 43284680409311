<template>
	
	<div>

		<div class="site-wrapper no-header">

			<!-- <ul class="scroller">
		    <li><router-link to="my-dashboard">Dashboard</router-link></li>
				<li><a @click.prevent="goBack()">Back</a></li>
		  </ul> -->

		
			<div v-if="!somethingsWrong" class="container">

				<!-- <p>Name: {{firebasedisplayname}}</p>
				<p>UID: {{firebaseid}}</p> -->
				<!-- <p>Payment Form Loaded? {{paymentFormIsLoaded}}</p> -->
				<!-- <p>
          Sent ID number: {{id}}<br>
          userID: {{userID}}
          Change date: {{changeDate}}<br>
          Show Payment Form: {{showPaymentForm}}<br>
					Today's Charge Amount: {{todayChargeAmount}}<br>Name: {{userName}}<br>UserID: {{userID}}<br>
		    	newAnnualChargeAmount: {{newAnnualChargeAmount}}<br>
		    	updatedUserRole: {{updatedUserRole}}<br>
		    	updatedMaxVessels: {{updatedMaxVessels}}<br>
		    	newRenewalDate: {{newRenewalDate}}<br>
		    </p> -->

		    <!-- <p>newRenewalDate: {{newRenewalDate}}<br></p> -->
        <!-- {{somethingsWrong ? 'Something is wrong' : 'Everything is fine'}} -->

				<div class="notification is-primary has-text-centered">
					<p v-if="!paymentSuccess" class="is-size-5"><font-awesome-icon icon="lock" /> Today's charge: <span>${{todayChargeAmount}}</span></p>
					<p v-else class="is-size-5"><font-awesome-icon icon="lock" /> Thank you!</p>
				</div>

				<div v-if="!paymentSuccess" class="box">
					<p>Once payment has been made, the truck {{truckName}} will be subscribed for another 12 months.</p>
				</div>

				<div ref="blurForm" v-show="!paymentSuccess" id="sq-ccbox">
			    <!--
			      You should replace the action attribute of the form with the path of
			      the URL you want to POST the nonce to (for example, "/process-card")
			    -->
			    <!-- <form id="nonce-form" novalidate action="admin-square-nonce" method="post"> -->
			    <!-- <form id="nonce-form" novalidate> -->
			      <div class="errorbox">
			        <div class="error" v-for="error in errors" :key="error">
			          {{error}}
			        </div>
			      </div>
			      <div id="card-tainer">
			      	<div v-if="!paymentFormIsLoaded">Loading secure payment form...</div>
			        <div class="cardfields card-number" :id="sqID+'-sq-card-number'"><span></span></div>
			        <div class="cardfields expiration-date" :id="sqID+'-sq-expiration-date'"><span></span></div>
			        <div class="cardfields cvv" :id="sqID+'-sq-cvv'"><span></span></div>
			        <!-- <div class="cardfields postal-code" value="9999" :id="sqID+'-sq-postal-code'"><span>Loading...</span></div> -->
			      </div>
			      <br>
			      <input type="hidden" id="card-nonce" name="nonce">
			      <div id="sq-walletbox">
			        <div v-show=!applePay class="wallet-not-enabled">Apple Pay for Web not enabled</div>
			        <!-- Placeholder for Apple Pay for Web button -->
			        <button v-show=applePay :id="sqID+'-sq-apple-pay'" class="button-apple-pay"></button>

			        <div v-show=!masterpass class="wallet-not-enabled">Masterpass not enabled</div>
			        <!-- Placeholder for Masterpass button -->
			        <button v-show=masterpass :id="sqID+'-sq-masterpass'" class="button-masterpass"></button>
			      </div>
			    <!-- </form> -->
			  </div>

				<div class="card-message has-text-centered is-size-5">{{chargeMessage}}<p>&nbsp;</p></div>
        <div class="spacer s30">
          
        </div>

			  <div class="buttons has-text-centered">
				  <span :disabled="!paymentFormIsLoaded" v-if="!paymentSuccess" @click="requestCardNonce( $event)" class='productPurchase button is-medium is-primary'>Make Payment</span>
				  <router-link v-if="paymentSuccess" class="button is-medium is-info" to="my-dashboard">Go to My Dashboard</router-link>
				  <!-- <button @click="clearForm" class='button'>Clear</button> -->
				</div>

        <div class="centered"><span class="simple-link" @click="showHelp = true">Having Trouble?</span></div>

			  <!-- <button class="button" v-if="finalNonce != ''" @click="createCharge">Create Charge</button> -->

			</div>


      <div v-else class="container centered">
        <div class="spacer s20"></div>
        <div class="notification is-warning has-text-centered">
          <p lass="is-size-5"><font-awesome-icon icon="exclamation-triangle" /> Oops. Something has gone a little pear shaped.</p>
        </div>
        <p>Strange things can happen. To clean out any bugs please return to the Dashboard and click the button at the bottom of the page that says<br> "<strong>Refresh App</strong>".</p>
        <div class="spacer s20"></div>
        <div class="buttons centered">
          <router-link to="my-dashboard" class="button is-info">Go back to the Dashboard</router-link>
          <router-link class="button is-primary" to="feedback">Or Contact Us for Help</router-link>
        </div>
      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{'is-active':showHelp}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-info">
              <p class="modal-card-title">Having trouble making a payment?</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">         
              <p>Strange things can happen. Please feel free to give us a call on <a href="tel:0409-709-596">0409 709 596</a> to get help.</p>
              <!-- <p>Strange things can happen. To clean out any bugs please return to the Dashboard and click the button at the bottom of the page that says "<strong>Refresh App</strong>".</p> -->
              <div class="spacer s10"></div>
              <div class="buttons">
                <router-link to="my-dashboard" class="button is-info">Go to the Dashboard</router-link>
                <router-link class="button is-primary" to="feedback">Or Contact Us for Help</router-link>
              </div>
              
            </section>
            <footer class="modal-card-foot">
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div>



		</div>

	</div>

</template>


<script>
	
	// import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import 'firebase/functions';
	import NProgress from 'nprogress';


	export default {

		//name: 'paymentForm',
		name: 'SquareNewCheckout',
		props: {
			showPaymentForm: Boolean,
    	id: Number,
    	userID: String,
    	userName: String,
    	todayChargeAmount: Number,
    	newAnnualChargeAmount: Number,
    	// updatedUserRole: String,
    	// updatedMaxVessels: Number,
    	newRenewalDate: [String, Object, Date],
    	// changeDate: Boolean
      truckID: String,
      truckName: String
		},



		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );
			NProgress.start();
			// start square functions
			// APP NAME: Offshore SMS Web App
			// let locationId = '1GP4THBJTTGA2'; // SANDBOX
      let locationId = '40ZFX2VT9XGE3'; // LIVE APP
      let applicationId = 'sq0idp-ej-cetjlmyqciy2qvT9uNw'; // LIVE APP
	    // let applicationId = 'sandbox-sq0idb-yXD4w_Z9dEq9fpq3dfXV_g'; // SANDBOX
	    let self = this;

	    console.log('Trace 1.');

	    this.paymentForm = new SqPaymentForm({
	      autoBuild: false,
	      applicationId: applicationId,
	      locationId: locationId,
	      inputClass: "sq-input",
	      // Initialize the payment form elements

	      // Customize the CSS for SqPaymentForm iframe elements
	      inputStyles: [
	        {
	          fontSize: "1em",
	          lineHeight: "1em",
	        }
	      ],

	      // Initialize Apple Pay placeholder ID
	      applePay: {
	        elementId: self.sqID + "-sq-apple-pay"
	      },

	      // Initialize Masterpass placeholder ID
	      masterpass: {
	        elementId: self.sqID + "-sq-masterpass"
	      },

	      // Initialize the credit card placeholders
	      cardNumber: {
	        elementId: self.sqID + "-sq-card-number",
	        placeholder: "Card number"
	      },
	      cvv: {
	        elementId: self.sqID + "-sq-cvv",
	        placeholder: "CVV"
	      },
	      expirationDate: {
	        elementId: self.sqID + "-sq-expiration-date",
	        placeholder: "MM / YY"
	      },
	      // postalCode: {
	      //   elementId: self.sqID + "-sq-postal-code",
	      //   placeholder: "Zip Code",
	      // },
	      postalCode: false,

	      // SqPaymentForm callback functions
	      callbacks: {
	        /*
	           * callback function: methodsSupported
	           * Triggered when: the page is loaded.
	           */
	        methodsSupported: function(methods) {
	          // Only show the button if Apple Pay for Web is enabled
	          // Otherwise, display the wallet not enabled message.
	          self.applePay = methods.applePay;
	          self.masterpass = methods.masterpass;
	        },

	        /*
	           * Digital Wallet related functions
	           */
	        createPaymentRequest: function() {
	          var paymentRequestJson;
	          /* ADD CODE TO SET/CREATE paymentRequestJson */
	          return paymentRequestJson;
	        },
	        validateShippingContact: function(contact) {
            console.log('contact',contact);
	          var validationErrorObj;
	          /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
	          return validationErrorObj;
	        },

	        /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           */
	        cardNonceResponseReceived: function(errors, nonce, cardData) {
	          if (errors) {
              console.log('cardData',cardData);
	          	self.errors = [];
	            errors.forEach(function(error) {
	              self.errors.push(error.message);
	            });
	            return;
	          }
	          // Assign the nonce value to the hidden form field
	          document.getElementById("card-nonce").value = nonce;

	          self.haveNonce = true;
	          self.finalNonce = nonce;
	          self.chargeMessage = 'Charging your card. This should only take a minute...';
	          //console.log('Fnal: ',self.haveNonce,self.finalNonce);
	          self.createCharge();	          

	          // POST the nonce form to the payment processing page
	          //document.getElementById("nonce-form").submit();
	        },
	        /*
	           * callback function: paymentFormLoaded
	           * Triggered when: SqPaymentForm is fully loaded
	           */
	        paymentFormLoaded: function() {
	        	console.log('Trace 4.');
	          /* HANDLE AS DESIRED */
	          console.log("paymentFormLoaded");
	          self.paymentFormIsLoaded = true;
	          self.paymentForm.focus("cardNumber");
	          NProgress.done();
	          var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
		        userHasEngaged({
		          userID: self.userID, 
		          userName: self.userName,
		          message: 'A user has reached the SafeTruckie Square Checkout page'
		        }); 
		        console.log('User engaged function called.');
	        },



	      }
	    });
	    console.log('Trace 2.');
	    this.paymentForm.build();
	    console.log('Trace 3.');

		},

		data() {
			return {

				firebaseid: '',
				firebasedisplayname: '',

				errors: [],
	      masterpass: false,
	      applePay: false,

	      finalNonce: '',
	      haveNonce: false,
	      paymentFormIsLoaded: false,

	      sqID: 'new',
	      chargeMessage: '',

	      paymentSuccess: false,
        showHelp: false,
			}
		},

		watch: {
	    showPaymentForm: function() {
	      if (!this.showPaymentForm) {
	        return 1;
	      }
	      this.paymentForm.build();
	    }
	  },

		computed: {
			isUserAdmin() {
	      return this.$store.getters.getIsUserAdmin;
	    },
	    userData() {
	    	return this.$store.getters.getSubscriptionInfo;
	    },
      somethingsWrong() {
        return this.todayChargeAmount == '' || this.todayChargeAmount == undefined || this.truckID == '' || this.truckID == undefined;
      }

		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseid = user.uid;
	      	this.firebasedisplayname = this.userData.username;
	      }
      },

			goBack: function() {
				this.$router.go(-1);
			},

      requestCardNonce: function(event) {
	      // Don't submit the form until SqPaymentForm returns with a nonce
	      event.preventDefault();
	      this.chargeMessage = 'Charging your card. This should only take a minute...';

	      // Request a nonce from the SqPaymentForm object
	      this.paymentForm.requestCardNonce();

	      //console.log('Nonce again: ',this.finalNonce);
	    },

	    createCharge: function() {

	    	NProgress.start();
	    	console.log('Charging card... ',this.finalNonce);
	    	//console.log(this.finalNonce,this.todayChargeAmount);

	    	this.$refs.blurForm.blur();
	    	let self = this;

	    	if (self.todayChargeAmount) {

		    	var chargeSquare = firebase.functions().httpsCallable('chargeSquare');
	        chargeSquare({
	        	// userID: self.userID, 
	        	// userName: self.userName,
	        	nonce: self.finalNonce,
	        	amount: self.todayChargeAmount * 100 // in cents remember!!
	        }).then(function(result) {
	        	let sanitizedMessage = result.data.text;
	        	if (result.data.message == 'Success') {
	        		// charge successful, update database with new userdata:
	        		let futureDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
	        		var updateTruckAfterPayment = firebase.functions().httpsCallable('updateTruckAfterPayment');
			        updateTruckAfterPayment({
			        	truckID: self.truckID, //
                truckName: self.truckName, // need  need sent truckID from props
			        	nonce: self.finalNonce,
							  todayChargeAmount: self.todayChargeAmount,
                renewalDate: futureDate,
							  newAnnualChargeAmount: self.newAnnualChargeAmount,					  
			        });
	        		self.chargeMessage = 'Your payment was successful!';
	        		// if (self.changeDate) {
		        	// 	db.collection('userdata').doc(self.userID).update({
		        	// 		anniversaryDate: futureDate
		        	// 	});
		        	// }
	        		self.clearForm();
	        		self.paymentSuccess = true;
	        		//self.paymentForm.destroy();
	        	} else {
	        		self.chargeMessage = 'Oops. That didn\'t work. Please check your card details and try again.';
	        		// charge not successful, done nothing in the DB.
	        	}
	        	console.log(sanitizedMessage);
	        	//console.log("Card Status: ",sanitizedMessage.transaction.tenders.card_details.status);
	        	NProgress.done();
	        }).catch(function(error) {
	        	console.log('Error: ',error);
	        	console.log('Text Error: ',error.text);
	        	//console.log(error.code,error.message,error.details);
	        	self.chargeMessage = 'Something went wrong. Please contact us on 0409 709 596 so we can help.';
	        	NProgress.done();
	        });  

	      }



	    },

	    clearForm: function() {
	    	this.errors = [];

	    },

      close() {
        this.showHelp = false;
      }


		},

		beforeRouteLeave (to, from, next) {
	    // called when the route that renders this component is about to
	    // be navigated away from.
	    // has access to `this` component instance.
	    console.log(this.userName,"is leaving the building...");
	    console.log(to.name);
	    this.paymentForm.destroy();
	    next()
	  },

	}

	// {
	// 	"transaction":{
	// 		"id":"innuUE6QfnPGnk5yA6aOpHEcU110bPc4yVNlBoEMCLFQWJL5HPBZOIhO",
	// 		"location_id":"CBASEKP3bpw6c_ERH62fwV2xpWcgAQ",
	// 		"created_at":"2019-07-24T02:02:01Z",
	// 		"tenders":[
	// 			{
	// 				"type":"CARD",
	// 				"id":"fa50eb7b-0c8f-50d5-7071-3d39da085064",
	// 				"location_id":"CBASEKP3bpw6c_ERH62fwV2xpWcgAQ",
	// 				"transaction_id":"innuUE6QfnPGnk5yA6aOpHEcU110bPc4yVNlBoEMCLFQWJL5HPBZOIhO",
	// 				"created_at":"2019-07-24T02:02:01Z",
	// 				"note":"Online Transaction",
	// 				"amount_money":{
	// 					"amount":130,
	// 					"currency":"AUD"
	// 				},
	// 				"card_details":{
	// 					"status":"CAPTURED",
	// 					"card":{
	// 						"card_brand":"VISA",
	// 						"last_4":"4242",
	// 						"fingerprint":"f083e86a048a894c3debcc0c6dc1db775b4ae8e4f80883fa5c78067c3b8b4272"
	// 					},
	// 					"entry_method":"KEYED"
	// 				}
	// 			}
	// 		],
	// 		"product":"EXTERNAL_API"
	// 	}
	// }


</script>





<style lang="scss">

.sq-input {
  border: 1px solid rgb(223, 223, 223);
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
  border: none;
  color: #32325d;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  height: 18px;
  -webkit-font-smoothing: antialiased;
}

.sq-input ::placeholder {
  color: #aab7c4;
  opacity: 0.5;
}

/* Define how SqPaymentForm iframes should look when they have focus */

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 3px auto rgb(255, 97, 97);
}

.errorbox {
  line-height: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  .error {
	  font-size: 10px;
	  color: rgb(164, 0, 30);
	  width: 100%;
	  display: inline-block;
	  font-weight: 400;
	  font-size: 0.8rem;
	}
}



/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  font-weight: bold;
  cursor: pointer;
}

.card-number {
  width: 100%;
}

.modal .payButton {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  width: 400px;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
}

#sq-walletbox {
  text-align: center;
  vertical-align: top;
  font-weight: bold;
  visibility: hidden;
  height: 1px;
}

#sq-ccbox {
  margin-top: 1rem;
  padding: 0;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

// .expiration-date,
// .cvv,
// .postal-code {
//   width: 45%;
//   display: inline-block;
// }

.expiration-date {
	width: 48%;
	display: inline-block; 
	margin-right: 4%;
}

.cvv {
	width: 48%;
	display: inline-block; 
	margin-right: 0;
}

#card-tainer {
  text-align: left;
  margin-top: 8px;
  background-color: white;
  // height: 80px;
  padding: 10px 12px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
	iframe.sq-input {
    padding: 14px;
    height: 50px;
	}
	.cardfields {
		span {
			font-style: italic;
			color: #ddd;
			font-size: 0.9rem;
		}
	}
}


/* Define how SqPaymentForm iframes should look */
.sq-input {
  height: 56px;
  box-sizing: border-box;
  border: 1px solid #E0E2E3;
  background-color: white;
  border-radius: 6px;
  display: inline-block;
  transition: border-color .2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #6E4EEC;
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #E02F2F;
}

#sq-card-number {
  margin-bottom: 16px;
}

/* Customize the "Pay with Credit Card" button */
.button-credit-card {
  border-radius: 50px;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.08),
    0px 1px 3px rgba(0, 0, 0, 0.04);
  height: auto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-top: 16px;
  padding: 16px;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg,#8E72FF -57.14%,#6E4EEC 125%);
  border: none;
  color: #fff;
}

.button-credit-card:hover {
  background: linear-gradient(180deg,#a791ff -57.14%,#856aef 125%);
  color: #fff;
}

.notification {
	p {
		font-size: 1.1rem;
		span {
			font-weight: bold;
	    color: greenyellow;
		}
	}
}



</style>






